import React from "react";
import "./App.css";
import Navigation from "./Components/Menu/Navigation";
import { BrowserRouter as Router } from "react-router-dom";
import RoutesPubliques from "./Routes/RoutesPubliques";
import NavigationMobile from "./Components/Menu/NavigationMobile";
import Footer from "./Components/Footer/Footer";
import { HashLink } from "react-router-hash-link";
import { InscriptionProvider } from "./Context/InscriptionContext";

function App() {
  return (
    <div className="App">
      <InscriptionProvider>
        <Router>
          <header>
            <HashLink to="/">
              <img
                src={process.env.PUBLIC_URL + "/LogoSinobi.svg"}
                alt="Logo Sinobi"
                className="Logo"
              />
            </HashLink>

            <div className="Navigation">
              <Navigation />
            </div>
            <div className="NavigationMobile">
              <NavigationMobile />
            </div>
          </header>
          <RoutesPubliques />
          <Footer />
        </Router>
      </InscriptionProvider>
    </div>
  );
}

export default App;
