import React from "react";
import BoutonActionPrincipale from "../../Design/Boutons/BoutonActionPrincipale";
import BoutonActionSecondaire from "../../Design/Boutons/BoutonActionSecondaire";
import MonStyle from "./Navigation.module.css";
import { HashLink } from "react-router-hash-link";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import BoutonInscription from "../../Design/Boutons/BoutonInscription";

export default function Navigation() {
  let location = useLocation();
  let pathname = location?.pathname;

  const handleClickConnexion = () => {
    // Send a custom event
    ReactGA.event({
      category: "BOUTONS",
      action: "bouton_connexion",
      label: "Clic sur le bouton connexion", // optional
      // value: 99, // optional, must be a number
      // nonInteraction: true, // optional, true/false
      //transport: "xhr", // optional, beacon/xhr/image
    });
  };

  return (
    <div className={MonStyle.Navigation}>
      <nav className={MonStyle.Liens}>
        <HashLink
          className={pathname === "/" ? MonStyle.LienSelected : MonStyle.Lien}
          to="/"
        >
          Découvrir Sinobi
        </HashLink>
        <HashLink
          className={
            pathname === "/support" ? MonStyle.LienSelected : MonStyle.Lien
          }
          to="/support"
        >
          Support
        </HashLink>
        <HashLink
          className={
            pathname === "/tarif" ? MonStyle.LienSelected : MonStyle.Lien
          }
          to="/tarif"
        >
          Tarifs
        </HashLink>
        <HashLink
          className={
            pathname === "/contact" ? MonStyle.LienSelected : MonStyle.Lien
          }
          to="/contact"
        >
          Nous contacter
        </HashLink>
      </nav>
      <div className={MonStyle.Boutons}>
        <BoutonInscription texte="Inscription" />

        <BoutonActionSecondaire
          texte="Connexion"
          href={process.env.REACT_APP_URL_SINOBI}
          onClick={handleClickConnexion}
        />
      </div>
    </div>
  );
}
