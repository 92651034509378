import React from "react";
import ArticleLanding from "../Components/Articles/ArticleLanding";
import ArticleDossier from "../Components/Articles/ArticleDossier";
import ArticleAgenda from "../Components/Articles/ArticleAgenda";

export default function PageProduit() {
  return (
    <div>
      <ArticleLanding />
      <ArticleAgenda />
      <ArticleDossier />
    </div>
  );
}
