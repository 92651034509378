import React, { useState } from "react";
import { HashLink } from "react-router-hash-link";
import MonStyle from "./MenuSupport.module.css";
import DataMenuSupport from "../../Data/DataMenuSupport.json";
import { useLocation } from "react-router";

function ItemMenu({ url, libelle, selected, ...props }) {
  return (
    <HashLink
      className={selected ? "MenuSelected" : "Menu"}
      to={url}
      {...props}
    >
      {libelle}
    </HashLink>
  );
}

export default function MenuSupport() {
  //NES gestion du state
  // eslint-disable-next-line
  const [data, setData] = useState(DataMenuSupport.data);

  const { pathname } = useLocation();

  return (
    <div className={MonStyle.MenuSupport}>
      {data.map((groupe) => (
        <div className={MonStyle.BlocMenu} key={groupe.nom_groupe}>
          <h4 style={{ color: "#213945" }}>{groupe.nom_groupe}</h4>

          {groupe.items.map((item) => (
            <ItemMenu
              url={item.url}
              libelle={item.libelle}
              selected={pathname === item.url}
              key={item.url}
            />
          ))}
        </div>
      ))}
    </div>
  );
}
