import React, { useContext, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import PageContact from "../Pages/PageContact";
import PageMentionsLegales from "../Pages/PageMentionsLegales";
import PageProduit from "../Pages/PageProduit";
import PageSupport from "../Pages/PageSupport";
import PageTarif from "../Pages/PageTarif";
import Page404 from "../Pages/Page404";
import MenuSupport from "../Components/Menu/MenuSupport";
import ReactGA from "react-ga4";
import { InscriptionContext } from "../Context/InscriptionContext";

export default function RoutesPubliques() {
  let location = useLocation();
  const { parrainage } = useContext(InscriptionContext);

  //NES GoogleAnalytics
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: location.pathname,
    });

    if (location.pathname === "/wilbois") {
      parrainage(location.pathname);
    }
  }, [location]);

  return (
    <Routes>
      <Route path="/" exact element={<PageProduit />} />
      <Route path="/wilbois" exact element={<PageProduit />} />
      <Route path="support/*" element={<PageSupport />}>
        <Route path="*" index element={<MenuSupport />} />
      </Route>
      <Route path="/tarif" exact element={<PageTarif />} />
      <Route path="/contact" exact element={<PageContact />} />
      <Route path="/mentionslegales" exact element={<PageMentionsLegales />} />
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
}
