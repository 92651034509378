import React, { useState } from "react";
import InputEmail from "../Design/Formulaires/InputEmail";
import InputTelephone from "../Design/Formulaires/InputTelephone";
import InputTexte from "../Design/Formulaires/InputTexte";
import MonStyle from "./PageContact.module.css";
import BoutonActionPrincipale from "../Design/Boutons/BoutonActionPrincipale";
import Alert from "@mui/material/Alert";
import { POST } from "../Functions/FunctionsApi";
import Collapse from "@mui/material/Collapse";
import IllustrationEnvoyerMail from "../Images/IllustrationEnvoyerMail.svg";
import IllustrationContact from "../Images/IllustrationContact.png";

export default function PageContact() {
  //NES gestion du state
  const [messageErreur, setMessageErreur] = useState("");
  const [isErrorMessage, setIsErrorMessage] = useState(false);
  const [isErrorContact, setIsErrorContact] = useState(false);
  const [isMessageOk, setIsMessageOk] = useState(false);
  const [isMessageKo, setIsMessageKo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState({
    message: "",
    email: "",
    telephone: "",
    code_role: "ADPX",
  });

  ///handleChange
  const handleChange = (key, value) => {
    let tempData = data;
    tempData = { ...tempData, [key]: value };
    setData(tempData);
  };

  ///HandleEnvoyer
  const handleEnvoyer = () => {
    //NES je réinitialise
    setMessageErreur("");
    setIsErrorMessage(false);
    setIsErrorContact(false);

    //NES pour la gestion facile des erreurs
    let message = "";

    //NES je teste qu'il y a bien un email ou un téléphone
    if (!data.email && !data.telephone) {
      message =
        "Nous avons besoin d'un email ou d'un numéro de téléphone pour vous répondre.";
      setIsErrorContact(true);
    }
    //NES je teste qu'il y a bien un message
    if (!data.message) {
      message = "Vous n'avez pas renseigné de message.";
      setIsErrorMessage(true);
    }

    //NES MAJ du state
    setMessageErreur(message);

    //NES si pas de message j'envoie
    if (!message) {
      envoyerDemande();
    }
  };

  //POST
  const envoyerDemande = async () => {
    let monUrl = "https://messages.latelierdupixel.com/allemail";

    let body = {
      adresse_email: "latelierdupixel@gmail.com",
      subject: "Nouvelle demande depuis SHOP SINOBI",
      html: `<div><h1>Nouveau message depuis le shop Sinobi</h1><hr/><p>${data.message}</p><hr/><p>${data.email}</p><p>${data.telephone}</p></div>`,
      code_role: "ADPX",
    };

    //NES loading
    setIsLoading(true);

    POST(monUrl, "", body).then((reponse) => {
      console.log(reponse);
      setIsLoading(false);

      if (reponse.code === "OK") {
        setIsMessageOk(true);
        setIsMessageKo(false);
      } else {
        setIsMessageKo(true);
        setIsMessageOk(false);
      }
    });
  };

  return (
    <div className={MonStyle.PageContact}>
      <article className={MonStyle.IllustrationGauche}>
        <img
          src={IllustrationContact}
          alt=""
          className={MonStyle.IllustrationContact}
        />
      </article>
      <article className={MonStyle.PartieDroite}>
        <div className={MonStyle.BlocTitre}>
          <h1>Nous contacter</h1>
          <p className="SousTitre">Vous avez une question? N'hésitez pas!</p>
        </div>
        <InputTexte
          label="Quel est votre message ? "
          placeholder="Ecrivez ici ..."
          multiline
          minRows={3}
          onChange={(e) => handleChange("message", e.target.value)}
          error={isErrorMessage}
        />
        <div className={MonStyle.BlocTitre}>
          <h2>Pour vous répondre</h2>
          <p className="SousTitre">
            Comment doit-on vous recontacter? Laissez nous votre email ou votre
            téléphone.
          </p>
        </div>

        <InputEmail
          onChange={(e) => handleChange("email", e.target.value)}
          error={isErrorContact}
        />
        <InputTelephone
          onChange={(e) => handleChange("telephone", e.target.value)}
          error={isErrorContact}
        />

        <Collapse in={messageErreur ? true : false}>
          <Alert style={{ marginTop: "20px" }} severity="error">
            {messageErreur}
          </Alert>
        </Collapse>

        {isMessageOk ? (
          <div className={MonStyle.Confirmation}>
            <img
              src={IllustrationEnvoyerMail}
              alt=""
              className={MonStyle.IllustrationSend}
            />
            <h3>Votre message a bien été envoyé !</h3>
            <p>Nous allons vous répondre rapidement!</p>
          </div>
        ) : (
          <BoutonActionPrincipale
            taille="L"
            texte="Envoyer le message"
            style={{ marginTop: "20px" }}
            onClick={handleEnvoyer}
            isLoading={isLoading}
          />
        )}

        <Collapse in={isMessageKo}>
          <Alert severity="error" variant="filled">
            Erreur lors de l'envoi du message, vérifiez que vous êtes bien
            connecté à internet.{" "}
          </Alert>
        </Collapse>
      </article>
    </div>
  );
}
