import React from "react";
import MonStyle from "./ArticleLanding.module.css";
import BoutonActionPrincipale from "../../Design/Boutons/BoutonActionPrincipale";
import Illustration1 from "../../Images/Illustration1.svg";
import IllustrationLaptop from "../../Images/Laptop.png";
import IllustrationIpad from "../../Images/Ipad.png";
import IllustrationIphone from "../../Images/Iphone.png";
import BoutonInscription from "../../Design/Boutons/BoutonInscription";

export default function ArticleLanding() {
  return (
    <article className={MonStyle.Article1}>
      <img src={Illustration1} className={MonStyle.Illustration1} alt="" />

      <div className={MonStyle.TexteArticle1}>
        <h4>
          Logiciel de gestion <br /> de cabinet de médecine chinoise <br /> et
          médecines douces
        </h4>
        <h1>Avec vous, partout, simplement.</h1>
        <p>
          Nous avons créé un outil tout simple mais incroyablement complet. De
          la prise de rendez-vous, à l'impression d'un conseil en passant par le
          suivi des séances... <br />{" "}
          <strong>L'aventure Sinobi commence ici!</strong>
        </p>

        <BoutonInscription
          texte="Essayer Sinobi "
          texteComplementaire="(30 jours d'essai)"
        />
      </div>
      <div className={MonStyle.ImagesArticle1}>
        <img
          src={IllustrationLaptop}
          className={MonStyle.IllustrationLaptop}
          alt=""
        />
        <img
          src={IllustrationIpad}
          className={MonStyle.IllustrationIpad}
          alt=""
        />
        <img
          src={IllustrationIphone}
          className={MonStyle.IllustrationIphone}
          alt=""
        />
      </div>
    </article>
  );
}
