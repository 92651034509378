import React from "react";
import IllustrationEquipeSupport from "../../../Images/IllustrationEquipeSupport.png";

export default function ArticleSupportLanding(params) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <h4>Aide et support</h4>
      <h1>Même les meilleurs ont parfois besoin d'aide</h1>
      <p>
        Toute l'équipe Sinobi est là pour vous aider, car nous savons à quel
        point c'est pénible de ne pas avoir de réponse... de solutions.... C'est
        bien pour cela que le support est directement intégré dans Sinobi !
      </p>
      <img
        src={IllustrationEquipeSupport}
        alt=""
        style={{ maxWidth: "100%" }}
      />
    </div>
  );
}
