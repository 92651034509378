import React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";

const MonBoutonActionPrincipale = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  fontWeight: 600,
  padding: "10px 20px",
  border: "1px solid #D2205F",
  lineHeight: 1.5,
  backgroundColor: "#D2205F",
  fontFamily: "Lato",
  color: "white",
  height: "50px",
  borderRadius: "6px",
  "&:hover": {
    backgroundColor: "#D2205F",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    //backgroundColor: "#0062cc",
    //borderColor: "#005cbf",
  },
  "&:focus": {
    //boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});

export default function BoutonActionPrincipale({
  texte,
  texteComplementaire,
  isLoading,
  ...props
}) {
  if (isLoading) {
    return (
      <MonBoutonActionPrincipale size="large" {...props}>
        <CircularProgress
          sx={{ color: "white", maxWidth: "30px", maxHeight: "30px" }}
        />
      </MonBoutonActionPrincipale>
    );
  }

  return (
    <MonBoutonActionPrincipale size="large" {...props}>
      {texte}{" "}
      {texteComplementaire && (
        <span style={{ fontWeight: "lighter", marginLeft: "8px" }}>
          {texteComplementaire}
        </span>
      )}
    </MonBoutonActionPrincipale>
  );
}
