import React from "react";
import { useState, useEffect } from "react";
import MonStyle from "./ArticleAgenda.module.css";
import IllustrationAgenda from "../../Images/IllustrationAgenda.png";
import IllustrationRdv from "../../Images/IllustrationRdv.png";
import { useRef } from "react";
import IllustrationSmsRdv from "../../Images/IllustrationSmsRdv.png";
import IllustrationDureeRdv from "../../Images/IllustrationDureeRdv.png";
import IllustrationSuiviRdv from "../../Images/IllustrationSuiviRdv.png";
import IllustrationDossierRdv from "../../Images/IllustrationDossierRdv.png";
import IllustrationUtilisatriceMobile from "../../Images/IllustrationUtilisatriceMobile.png";

export default function ArticleAgenda(params) {
  //NES gestion des ref
  const element = useRef();

  ///Gestion de l'apparition
  const [isIntersecting, setIntersecting] = useState(false);
  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting)
    );

    observer.observe(element.current);
    return () => {
      observer.disconnect();
    };
  }, [element]);

  return (
    <article className={MonStyle.ArticleAgenda}>
      <div className={MonStyle.Titre}>
        <h4 className="h4White">Agenda</h4>
        <h1 className="h1White">Vous avez rendez vous !</h1>
        <p className="pWhite">
          La prise de rendez vous est directement intégrée dans l'outil. Vous
          avez un seul dossier patient ... à jour ! Gérez vos disponibilités,
          vos types de rendez-vous, et voilà !
        </p>
      </div>
      <div className={MonStyle.IllustrationsAgenda} ref={element}>
        <img
          src={IllustrationAgenda}
          alt=""
          className={MonStyle.IllustrationAgenda}
        />
        <img
          src={IllustrationRdv}
          alt=""
          className={
            isIntersecting
              ? MonStyle.IllustrationRdv
              : MonStyle.IllustrationRdvNonVisible
          }
        />
      </div>
      <div className={MonStyle.BlocSousArticles}>
        <article className={MonStyle.SousArticle}>
          <img
            src={IllustrationSmsRdv}
            alt=""
            className={MonStyle.IllustrationSousArticle}
          />
          <h2 className="h2White">Confirmez par sms, emails</h2>
          <div className="pWhite">
            Vous pouvez confirmer à votre patient la prise de rendez-vous par
            email, par sms. Vous pouvez:
            <ul>
              <li>Confirmer le rendez-vous</li>
              <li>Rappeler le rendez-vous à votre patient</li>
              <li>Confirmer l'annulation du rendez-vous</li>
            </ul>
          </div>
        </article>
        <article className={MonStyle.SousArticle}>
          <img
            src={IllustrationDureeRdv}
            alt=""
            className={MonStyle.IllustrationSousArticle}
          />
          <h2 className="h2White">Gérez vos types de rdv</h2>
          <div className="pWhite">
            Tous les rendez-vous ne sont pas les mêmes ! Vous pouvez
            personnaliser :
            <ul>
              <li>Le nom du type de rendez vous</li>
              <li>La durée par défaut</li>
              <li>La couleur d'affichage</li>
            </ul>
          </div>
        </article>
        <article className={MonStyle.SousArticle}>
          <img
            src={IllustrationSuiviRdv}
            alt=""
            className={MonStyle.IllustrationSousArticle}
          />

          <h2 className="h2White">Suivez les rdv non honorés</h2>
          <p className="pWhite">
            Lors de la prise de rendez-vous on vous affiche directement les
            rendez-vous excusés, annulés à la dernière minute et les rendez-vous
            non excusés.
          </p>
        </article>
        <article className={MonStyle.SousArticle}>
          <img
            src={IllustrationDossierRdv}
            alt=""
            className={MonStyle.IllustrationSousArticle}
          />

          <h2 className="h2White">Un seul dossier... à jour !</h2>
          <p className="pWhite">
            Vous avez un seul outil ! Et donc un seul dossier patient. Celui ci
            est mis à jour directement depuis la prise de rendez vous, par
            exemple : ajouter un numéro de téléphone, corriger son adresse
            email...
          </p>
        </article>
      </div>
      <div className={MonStyle.BlocTemoignage}>
        <div className={MonStyle.BlocIllustrationTemoignage}>
          <img
            src={IllustrationUtilisatriceMobile}
            alt=""
            className={MonStyle.IllustrationTemoignage}
          />
          <q className="qWhite">
            C'est quand même bien pratique, d'avoir d'accès à mon planning sans
            retourner à mon ordinateur !
          </q>
        </div>
        <div className={MonStyle.BlocTexteTemoignage}>
          <h2 className="h2White">A quelle heure je commence demain ?</h2>
          <p className="pWhite">
            Utilisez votre agenda Sinobi sur votre téléphone !
          </p>
          <p className="pWhite">
            Vous avez accès aux mêmes fonctionnalités, mais bien sûr c'est plus
            petit. Cela vous permet de consulter vos rendez-vous de n'importe où
            de bloquer des plages horaires, d'ajouter un rendez-vous au pied
            levé !
          </p>
        </div>
      </div>
    </article>
  );
}
