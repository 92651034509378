import React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

const MonBoutonActionSecondaire = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  fontWeight: 600,
  padding: "10px 20px",
  border: "1px solid",
  lineHeight: 1.5,
  backgroundColor: "",
  borderColor: "#283B48",
  fontFamily: "Lato",
  color: "#283B48",
  height: "50px",
  borderRadius: "6px",
  "&:hover": {
    backgroundColor: "#DDDDDD50",
    borderColor: "#D2205F",
    boxShadow: "none",
    color: "#D2205F",
    border: "1px solid #D2205F",
  },
  "&:active": {
    boxShadow: "none",
    //backgroundColor: "#0062cc",
    borderColor: "#005cbf",
  },
  "&:focus": {
    //boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});

export default function BoutonActionSecondaire({ texte, ...props }) {
  return (
    <MonBoutonActionSecondaire size="medium" {...props}>
      {texte}
    </MonBoutonActionSecondaire>
  );
}
