import React from "react";
import IllustrationAfficherConsultationArchive1 from "../../../Images/Support/IllustrationAfficherConsultationArchive1.png";
import IllustrationAfficherConsultationArchive2 from "../../../Images/Support/IllustrationAfficherConsultationArchive2.png";
import IllustrationAfficherConsultationArchive3 from "../../../Images/Support/IllustrationAfficherConsultationArchive3.png";
import IllustrationAfficherConsultationArchive4 from "../../../Images/Support/IllustrationAfficherConsultationArchive4.png";

export default function ArticleSupportAfficherConsultationsArchives(params) {
  return (
    <div>
      <h1>Afficher les consultations archivées </h1>
      <p>
        Si besoin, vous pouvez afficher les consultations archivées, grâce au
        bouton présent dans les filtres de recherche et d'affichage.
      </p>
      <h2>1. Utilisez le filtre consultations archivées</h2>
      <img
        src={IllustrationAfficherConsultationArchive1}
        alt=""
        className="ImageSupport"
      />
      <p>
        Dans les filtres de recherche, vous pouvez utilisez le filtre
        "consultations archivées", pour changer l'affichage et inclure les
        consultations archivées.
      </p>
      <h2>2. Vous retrouvez la consultation dans l'historique</h2>
      <img
        src={IllustrationAfficherConsultationArchive2}
        alt=""
        className="ImageSupport"
      />
      <p>
        Vous allez retrouver les consultations archivées (grisées) dans
        l'historique. En cliquant dessus, vous accédez à la consultation comme
        d'habitude.
      </p>
      <h2>3. Accédez à votre consultation archivée</h2>
      <img
        src={IllustrationAfficherConsultationArchive3}
        alt=""
        className="ImageSupport"
      />
      <p>
        Vous allez retrouver la personne qui a supprimé la consultation et la
        date et l'heure. Vous pouvez modifier votre consultation de manière
        classique.
      </p>
      <h2>4. Eventuellement, annulez l'archivage</h2>
      <img
        src={IllustrationAfficherConsultationArchive4}
        alt=""
        className="ImageSupport"
      />
      <p>
        En cliquant sur le bouton "Annuler", vous pouvez annuler la suppression,
        dans ce cas la consultation sera à nouveau affichée dans l'historique.
      </p>

      <q>On espère, que cet article vous a aidé.</q>
      <p> L'équipe Sinobi</p>
    </div>
  );
}
