import React from "react";
import BoutonActionSecondaire from "../Design/Boutons/BoutonActionSecondaire";
import MonStyle from "./Page404.module.css";

export default function Page404() {
  return (
    <article className={MonStyle.Page404}>
      <div className={MonStyle.Page}>
        <h4>Erreur 404</h4>
        <h1>La page recherchée n'existe pas</h1>
        <p>La page que vous recherchez n'existe pas, ou n'existe plus.</p>
        <BoutonActionSecondaire
          texte="Retourner à la page d'accueil"
          href="/"
        />
      </div>
    </article>
  );
}
