import React, { useState } from "react";
import MonStyle from "./NavigationMobile.module.css";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import BoutonTexte from "../../Design/Boutons/BoutonTexte";
import BoutonActionSecondaire from "../../Design/Boutons/BoutonActionSecondaire";
import BoutonActionPrincipale from "../../Design/Boutons/BoutonActionPrincipale";
import { Divider } from "@mui/material";
import BoutonInscription from "../../Design/Boutons/BoutonInscription";

export default function NavigationMobile() {
  //NES Gestion du state
  const [open, setOpen] = useState(false);

  ///handleOpen
  const handleOpen = () => {
    setOpen(true);
  };

  ///handleClose
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton aria-label="delete" onClick={handleOpen}>
        <MenuIcon />
      </IconButton>
      <Drawer anchor="right" open={open} onClose={handleClose}>
        <div className={MonStyle.Menu}>
          <header>
            <img
              src={process.env.PUBLIC_URL + "/LogoSinobi.svg"}
              alt="Logo Sinobi"
              className="Logo"
            />
            <IconButton aria-label="delete" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </header>
          <main className={MonStyle.Content}>
            <nav className={MonStyle.Liens}>
              <BoutonTexte
                texte="Découvrir Sinobi"
                href="/"
                fullWidth
                onClick={handleClose}
                style={{ fontSize: "20px" }}
              />
              <BoutonTexte
                texte="Support"
                href="/support"
                fullWidth
                onClick={handleClose}
                style={{ fontSize: "20px" }}
              />
              <BoutonTexte
                texte="Tarifs"
                href="/tarif"
                fullWidth
                onClick={handleClose}
                style={{ fontSize: "20px" }}
              />
              <BoutonTexte
                texte="Nous contacter"
                href="/contact"
                fullWidth
                onClick={handleClose}
                style={{ fontSize: "20px" }}
              />
            </nav>
            <Divider />
            <div className={MonStyle.Boutons}>
              <BoutonInscription texte="Inscription" />
              <BoutonActionSecondaire texte="Connexion" onClick={handleClose} />
            </div>
          </main>
        </div>
      </Drawer>
    </div>
  );
}
