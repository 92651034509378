import React from "react";
import MonStyle from "./ArticleDossier.module.css";
import IllustrationSynthese from "../../Images/IllustrationSynthese.png";
import IllustrationCommentaireEpingle from "../../Images/IllustrationCommentaireEpingle.png";
import IllustrationCommentaireImportant from "../../Images/IllustrationCommentaireImportant.png";
import IllustrationCommentairePersonnel from "../../Images/IllustrationCommentairePersonnel.png";
import IllustrationNote from "../../Images/IllustrationNote.png";
import IllustrationAllergie from "../../Images/IllustrationAllergie.png";
import IllustrationHabitus from "../../Images/IllustrationHabitus.png";

export default function ArticleDossier() {
  return (
    <article className={MonStyle.ArticleDossier}>
      <div className={MonStyle.Wrapper}>
        <div className={MonStyle.TextesArticle2}>
          <div className={MonStyle.Titre}>
            <h4>Dossier patient</h4>
            <h1>Vous avez tout sous les yeux!</h1>
            <p>
              Sinobi affiche à tout moment la synthèse de ce qui est important
              pour votre pratique!
            </p>
          </div>

          <h2>Des commentaires</h2>
          <div>
            Nous savons que les commentaires sont importants pour vous dans
            votre pratique. C'est pourquoi Sinobi permet toute sorte de
            commentaires !
            <ul>
              <li>
                <strong>Les commentaires épinglés</strong> : pour être toujours
                visibles
                <img
                  src={IllustrationCommentaireEpingle}
                  className={MonStyle.IllustrationMobile}
                  alt=""
                />
              </li>
              <li>
                <strong> Les commentaires importants</strong> : pour qu'ils vous
                sautent aux yeux quand vous ouvrez le dossier
                <img
                  src={IllustrationCommentaireImportant}
                  className={MonStyle.IllustrationMobile}
                  alt=""
                />
              </li>

              <li>
                <strong>Les commentaires privés</strong> : visibles uniquement
                par la personne qui l'a saisie.
                <img
                  src={IllustrationCommentairePersonnel}
                  className={MonStyle.IllustrationMobile}
                  alt=""
                />
              </li>
              <li>
                <strong>Les notes</strong> : pour faire la même chose qu'avec
                les petits bouts de papiers jaunes bien connus !
                <img
                  src={IllustrationNote}
                  className={MonStyle.IllustrationMobile}
                  alt=""
                />
              </li>
            </ul>
          </div>

          <h2>Allergies et antécédents</h2>
          <p>
            Renseignez les allergies et antécédents de vos patients pour les
            garder sous les yeux le temps de la consultation. Vous pouvez mettre
            en avant des cas posant problème pour votre pratique pour être sûr
            de ne pas passer à côté.
            <img
              src={IllustrationAllergie}
              className={MonStyle.IllustrationMobile}
              alt=""
            />
          </p>

          <h2>Habitus</h2>
          <p>
            En un clic, indiquez les habitus de votre patient (fumeur,
            alcool...) précisez éventuellement le contexte pour vous aider!
            <img
              src={IllustrationHabitus}
              className={MonStyle.IllustrationMobile}
              alt=""
            />
          </p>
        </div>
        <img
          src={IllustrationSynthese}
          className={MonStyle.IllustrationSynthese}
          alt=""
        />
      </div>
    </article>
  );
}
