import React from "react";
import MonStyle from "./PageSupport.module.css";
import { Routes, Route } from "react-router-dom";
import MenuSupport from "../Components/Menu/MenuSupport";
import ArticleSupportNouvelleDemande from "../Components/Articles/Support/ArticleSupportNouvelleDemande";
import ArticleSupportDateIntervention from "../Components/Articles/Support/ArticleSupportDateIntervention";
import Page404 from "./Page404";
import ArticleSupportLanding from "../Components/Articles/Support/ArticleSupportLanding";
import ArticleSupportArchiverConsultation from "../Components/Articles/Support/ArticleSupportArchiverConsultation";
import ArticleSupportAfficherConsultationsArchives from "../Components/Articles/Support/ArticleSupportAfficherConsultationsArchives";
import ArticleSupportAjouterTypeConsultation from "../Components/Articles/Support/ArticleSupportAjouterTypeConsultation";
import { isMobile } from "../Functions/FunctionsNavigateur";

export default function PageSupport({ match }) {
  return (
    <div className={MonStyle.PageSupport}>
      <div className={MonStyle.Wrapper}>
        <div className={MonStyle.Menu}>
          <MenuSupport match={match} />
        </div>
        <div className={MonStyle.PartieDroite}>
          <div className={MonStyle.Contenu}>
            <Routes>
              <Route
                path="/"
                element={
                  isMobile() ? (
                    <MenuSupport match={match} />
                  ) : (
                    <ArticleSupportLanding />
                  )
                }
              />

              <Route
                path="/nouvelle-demande"
                element={<ArticleSupportNouvelleDemande />}
              />
              <Route
                path="/date-d-intervention"
                element={<ArticleSupportDateIntervention />}
              />
              <Route
                path="/ajouter-type-consultation"
                element={<ArticleSupportAjouterTypeConsultation />}
              />
              <Route
                path="/archiver-consultation"
                element={<ArticleSupportArchiverConsultation />}
              />
              <Route
                path="/afficher-consultation-archive"
                element={<ArticleSupportAfficherConsultationsArchives />}
              />

              <Route path="*" element={<Page404 />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
}
