import React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { HashLink } from "react-router-hash-link";

const MonBoutonTexte = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  padding: "6px 12px",
  border: "0px solid",
  lineHeight: 1.5,
  backgroundColor: "",
  borderColor: "#0063cc",
  fontFamily: "Lato",
  color: "#666",
  letterSpacing: "0.5",
  textDecoration: "none",
  height: "50px",
  "&:hover": {
    backgroundColor: "#DDDDDD50",
    borderColor: "#0062cc",
    boxShadow: "none",
    color: "#283B48",
    textDecoration: "underline",
  },
  "&:active": {
    boxShadow: "none",
    //backgroundColor: "#0062cc",
    borderColor: "#005cbf",
  },
  "&:focus": {
    //boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});

export default function BoutonTexte({ texte, href, ...props }) {
  if (href) {
    return (
      <HashLink to={href} style={{ textDecoration: "none" }}>
        <MonBoutonTexte {...props}>{texte}</MonBoutonTexte>
      </HashLink>
    );
  }

  return <MonBoutonTexte {...props}>{texte}</MonBoutonTexte>;
}
