import React from "react";
import MonStyle from "./PageTarif.module.css";
import DataTarifs from "../Data/DataTarifs.json";
import DataFonctionnalites from "../Data/DataFonctionnalites.json";
import IllustrationEquipeHappy from "../Images/IllustrationEquipeHappy.png";
import ReactGA from "react-ga4";

function IsDisponible(value) {
  if (value) {
    return <div className={MonStyle.Disponible}> </div>;
  } else {
    return <div className={MonStyle.NonDisponible}> </div>;
  }
}

export default function PageTarif() {
  ReactGA.send({ hitType: "pageview", page: "/tarifs", title: "Tarifs" });

  return (
    <div className={MonStyle.PageTarif}>
      <article className={MonStyle.BlocTarifs}>
        <div className={MonStyle.Titre}>
          <h1>Tarifs</h1>
          <p>
            On aurait pu faire compliqué avec des options et des modules à
            ajouter... mais là aussi on a préféré faire simple!
          </p>
        </div>

        <div className={MonStyle.ListeTarif}>
          {DataTarifs.data.map((tarif, index) => (
            <div className={MonStyle.LigneTarif} key={index}>
              <h2>{tarif.libelle}</h2>
              <p className={MonStyle.Description}>{tarif.description}</p>
              <div className={MonStyle.Prix}>
                <h2>{tarif.tarif}</h2>
                <p className={MonStyle.PrixSecondaire}>
                  {tarif.complement_tarif}
                </p>
              </div>
            </div>
          ))}
        </div>
      </article>
      <div className={MonStyle.IllustrationEquipeHappy}>
        <img src={IllustrationEquipeHappy} alt="" />
      </div>
      <article className={MonStyle.Fonctionnalites}>
        <div className={MonStyle.Titre}>
          <h1>Fonctionnalités</h1>
          <p>Une petite liste des possiblités avec Sinobi. </p>
        </div>

        <div>
          {DataFonctionnalites.data.map((groupe) => (
            <div key={groupe.groupe_libelle}>
              <div className={MonStyle.GroupeLibelle}>
                <div className={MonStyle.FonctionnaliteLibelle}>
                  {groupe.groupe_libelle}
                </div>
                <h4 className={MonStyle.Role}>Praticien</h4>
                <h4 className={MonStyle.Role}>Secrétaire</h4>
              </div>

              {groupe.fonctionnalites.map((fonctionnalites) => (
                <div
                  className={MonStyle.LigneFonctionnalite}
                  key={fonctionnalites.fonctionnalite_libelle}
                >
                  <div className={MonStyle.FonctionnaliteLibelle}>
                    {fonctionnalites.fonctionnalite_libelle}
                  </div>
                  <div className={MonStyle.Role}>
                    {IsDisponible(fonctionnalites.profils[0])}
                  </div>
                  <div className={MonStyle.Role}>
                    {IsDisponible(fonctionnalites.profils[1])}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </article>
    </div>
  );
}
