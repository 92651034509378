import React from "react";
import IllustrationNouveauTypeConsultation1 from "../../../Images/Support/IllustrationNouveauTypeConsultation1.png";
import IllustrationNouveauTypeConsultation2 from "../../../Images/Support/IllustrationNouveauTypeConsultation2.png";
import IllustrationNouveauTypeConsultation3 from "../../../Images/Support/IllustrationNouveauTypeConsultation3.png";
import IllustrationNouveauTypeConsultation4 from "../../../Images/Support/IllustrationNouveauTypeConsultation4.png";
import IllustrationNouveauTypeConsultation5 from "../../../Images/Support/IllustrationNouveauTypeConsultation5.png";
import IllustrationNouveauTypeConsultation6 from "../../../Images/Support/IllustrationNouveauTypeConsultation6.png";

export default function ArticleSupportAjouterTypeConsultation(params) {
  return (
    <div>
      <h1>Ajouter un type de consultation</h1>
      <p>
        Si besoin, pour mieux gérer votre historique des consultations patients,
        vous pouvez ajouter des types de consultation.
      </p>
      <h4>Remarque importante :</h4>
      <p>
        <mark>
          Seul un utilisateur "administrateur" peut ajouter un type de
          consultation.
        </mark>
      </p>

      <h2>1. Accéder aux options</h2>
      <img
        src={IllustrationNouveauTypeConsultation1}
        alt=""
        className="ImageSupport"
      />
      <p>Dans le menu, cliquez sur options et paramètres.</p>
      <h2>2. Afficher les types de consultations</h2>
      <img
        src={IllustrationNouveauTypeConsultation2}
        alt=""
        className="ImageSupport"
      />
      <p>
        Vous allez retrouver les types de consultations par défaut de Sinobi
        (ceux-ci ne peuvent pas être modifiés, ni supprimés). Cliquez sur le
        bouton "Ajouter un type de consultation"
      </p>

      <h2>3. Ajouter un type de consultation</h2>

      <img
        src={IllustrationNouveauTypeConsultation3}
        alt=""
        className="ImageSupport"
      />
      <p>
        Vous pouvez choisir une couleur pour identifier facilement le type de
        consultation dans l'historique patient. Le nom du type de consultation
        est obligatoire pour pouvoir enregistrer.
      </p>
      <h2>4. Choisir le type d'affichage</h2>
      <img
        src={IllustrationNouveauTypeConsultation4}
        alt=""
        className="ImageSupport"
      />
      <p>Le type de consultation influence les champs de saisie affichés.</p>
      <h2>5. Le type de consultation est disponible</h2>

      <img
        src={IllustrationNouveauTypeConsultation5}
        alt=""
        className="ImageSupport"
      />
      <p>
        Vous retrouvez bien le type de consultation dans la liste des types
        consultation.
      </p>

      <h2>6. Eventuellement, choisir un type favori</h2>
      <img
        src={IllustrationNouveauTypeConsultation6}
        alt=""
        className="ImageSupport"
      />
      <p>
        Le type de consultation est bien disponible en consultation. Vous pouvez
        cliquer sur l'étoile pour que ce type de consultation soit sélectionné
        par défaut lors de la création d'une nouvelle consultation.
      </p>
    </div>
  );
}
