//POST
export async function POST(url, token, data) {
  let reponseAPI = await API("POST", url, token, data);
  return reponseAPI;
}

export async function API(method, url, token, data) {
  let couleurLog = "#000";

  switch (method) {
    case "GET":
      couleurLog = "#2E7FAE";
      break;
    case "POST":
      couleurLog = "#08AA08";
      break;
    case "PUT":
      couleurLog = "#C6258F";
      break;
    case "PATCH":
      couleurLog = "#FF9800";
      break;
    case "DELETE":
      couleurLog = "#D50A0A";
      break;
    default:
      break;
  }

  //NES pour détecter les appels en boucle
  console.log(
    `%c ${method} %c ${url}`,
    `background: ${couleurLog}; color: #FFF;`,
    `background: none; `
  );

  //NES gestion dynamique du token
  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  if (token) {
    headers = { ...headers, token: token };
  }
  let paramAppel = {
    method: method,
    headers: headers,
  };

  //NES si j'ai un body
  if (data) {
    paramAppel = { ...paramAppel, body: JSON.stringify(data) };
  }

  let reponseApi = await fetch(url, paramAppel);

  try {
    //NES est que la réponse est KO
    if (reponseApi.ok === false) {
      let reponseAvecErreur = {
        code: method,
        error: reponseApi.status + " - " + method + " : " + url,
        message: reponseApi.statusText,
        technical_stack: "",
        status: reponseApi.status,
      };
      return reponseAvecErreur;
    }

    let reponseJson = await reponseApi.json();
    reponseJson = { ...reponseJson, status: reponseApi.status };
    //NES est-ce que mon tocken a expiré?
    if (reponseJson.expiredAt) {
      let reponseAvecErreur = {
        code: "KO",
        message: "Votre compte a expiré, vous devez vous reconnecter",
        error: reponseJson.name + " - " + reponseJson.message + " - " + url,
      };
      return reponseAvecErreur;
    }

    //NES si tout va bien je retourne la réponse
    return reponseJson;
  } catch (error) {
    //NES pour détecter les appels en boucle
    console.log(error);

    let reponseAvecErreur = {
      code: method,
      error: reponseApi.status + " - " + method + " : " + url,
      message: reponseApi.statusText,
      technical_stack: "",
      status: reponseApi.status,
    };
    return reponseAvecErreur;
  }
}
