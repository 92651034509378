import React, { useContext } from "react";
import BoutonActionPrincipale from "./BoutonActionPrincipale";
import { InscriptionContext } from "../../Context/InscriptionContext";
import ReactGA from "react-ga4";

export default function BoutonInscription(props) {
  const { urlInscription } = useContext(InscriptionContext);

  const handleClickInscription = () => {
    ReactGA.event({
      category: "BOUTONS",
      action: "bouton_inscription",
      label: "Clic sur le bouton inscription", // optional
      // value: 99, // optional, must be a number
      // nonInteraction: true, // optional, true/false
      //transport: "xhr", // optional, beacon/xhr/image
    });
  };

  return (
    <BoutonActionPrincipale
      {...props}
      href={urlInscription}
      onClick={handleClickInscription}
    />
  );
}
