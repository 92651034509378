import React from "react";
import IllustrationNouvelleDemande1 from "../../../Images/Support/IllustrationNouvelleDemande1.png";
import IllustrationNouvelleDemande2 from "../../../Images/Support/IllustrationNouvelleDemande2.png";
import IllustrationNouvelleDemande3 from "../../../Images/Support/IllustrationNouvelleDemande3.png";
import IllustrationNouvelleDemande4 from "../../../Images/Support/IllustrationNouvelleDemande4.png";
import IllustrationNouvelleDemande5 from "../../../Images/Support/IllustrationNouvelleDemande5.png";
import IllustrationNouvelleDemande6 from "../../../Images/Support/IllustrationNouvelleDemande6.png";

export default function ArticleSupportNouvelleDemande(params) {
  return (
    <div>
      <h1>Nouvelle demande</h1>
      <p>
        Vous pouvez réaliser une demande de support et/ou proposer une
        amélioration du produit directement dans Sinobi.
      </p>
      <h2>1. Créer une nouvelle demande</h2>
      <img src={IllustrationNouvelleDemande1} alt="" className="ImageSupport" />
      <p>
        Utilisez le bouton en bas du menu pour créer une nouvelle demande. Il va
        effectuer en meme temps une capture d'écran pour nous permettre de bien
        comprendre le contexte de votre demande.{" "}
      </p>
      <img src={IllustrationNouvelleDemande2} alt="" className="ImageSupport" />
      <p>
        Vous pouvez ajouter cette capture d'écran à une demande existante, ou
        tout simplement créer une nouvelle demande de support.
      </p>
      <h2>2. Saisir votre demande</h2>
      <img src={IllustrationNouvelleDemande3} alt="" className="ImageSupport" />
      <p>
        Ajoutez maintenant le titre de votre demande, cela permettra de
        retrouver facilement votre demande dans le suivi. Ainsi qu'une
        description pour que l'on puisse bien comprendre le problème que vous
        rencontrez.
      </p>
      <h2>3. Aidez nous à comprendre le problème</h2>
      <img src={IllustrationNouvelleDemande4} alt="" className="ImageSupport" />
      <p>
        Le problème peut être une demande d'aide, un bug/anomalie ou une
        évolution permettant d'améliorer Sinobi. Cette étape est importante car
        elle va nous servir à définir automatiquement une priorité
        d'intervention.{" "}
      </p>
      <h2>4. Quelle est la priorité ?</h2>
      <img src={IllustrationNouvelleDemande5} alt="" className="ImageSupport" />
      <p>
        Renseignez la priorité de la demande pour vous! Mais jouez le jeu, car
        si tout le monde met la priorité en urgence, nous ne serons plus en
        mesure d'intervenir très très rapidement en cas de réelle urgence.{" "}
      </p>
      <h2>5. Votre demande est bien prise en compte!</h2>
      <img src={IllustrationNouvelleDemande6} alt="" className="ImageSupport" />
      <p>
        Votre demande à bien été transmise aux équipes Sinobi, vous allez avoir
        une date d'intervention prévisionelle et vous pourrez suivre en temps
        réel, notre avancée dans le menu support.{" "}
      </p>

      <q>On espère, que cet article vous a aidé.</q>
      <p> L'équipe Sinobi</p>
    </div>
  );
}
