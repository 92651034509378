import React from "react";
import MonStyle from "./Footer.module.css";
import ImageFooter from "../../Images/FooterSinobi.svg";
import { HashLink } from "react-router-hash-link";

export default function Footer() {
  return (
    <div className={MonStyle.Footer}>
      <img src={ImageFooter} className={MonStyle.Illustration} alt="" />
      <div className={MonStyle.Bas}>
        <div>Site réalisé par l'Atelier du pixel</div> |{" "}
        <HashLink to="/mentionslegales" className={MonStyle.Lien}>
          Mentions légales
        </HashLink>
        | <div>{process.env.REACT_APP_VERSION}</div>
      </div>
    </div>
  );
}
