import React, { createContext, useState } from "react";

//NES Création du contexte
export const InscriptionContext = createContext();

export const InscriptionProvider = (props) => {
  //NES gestion du state
  const [urlInscription, setUrlInscription] = useState(
    process.env.REACT_APP_URL_INSCRIPTION
  );

  //NES parrainage
  const parrainage = (parrain) => {
    let nomParrain = parrain.replace("/", "");

    let param = "?origine=" + nomParrain;

    //console.log({ parrain, nomParrain, param });

    setUrlInscription(process.env.REACT_APP_URL_INSCRIPTION + param);
  };

  return (
    <InscriptionContext.Provider
      value={{
        urlInscription,
        parrainage,
      }}
    >
      {props.children}
    </InscriptionContext.Provider>
  );
};
