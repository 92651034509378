import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import MarkunreadIcon from "@mui/icons-material/Markunread";

export default function InputEmail(props) {
  return (
    <TextField
      placeholder="Email"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <MarkunreadIcon />
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
}
