import React from "react";
import IllustrationArchiverConsultation1 from "../../../Images/Support/IllustrationArchiverConsultation1.png";
import IllustrationArchiverConsultation2 from "../../../Images/Support/IllustrationArchiverConsultation2.png";
import { HashLink } from "react-router-hash-link";

export default function ArticleSupportArchiverConsultation(params) {
  return (
    <div>
      <h1>Archiver une consultation</h1>
      <p>
        Vous pouvez si besoin archiver une consultation. Celle-ci ne sera plus
        visible (mais il est toujours possible de l'afficher si besoin).{" "}
      </p>
      <p>
        <h4>Remarque importante :</h4>
        <mark>
          Seule la personne ayant créée la consultation peut l'archiver
        </mark>
      </p>
      <h2>1. Accéder au bouton supprimer</h2>
      <img
        src={IllustrationArchiverConsultation1}
        alt=""
        className="ImageSupport"
      />
      <p>
        Descendez tout en bas de la consultation vous y trouverez les
        informations de tracabilités (qui, quand) ainsi que le bouton de
        suppression
      </p>
      <h2>2. Confirmation de la suppression</h2>
      <img
        src={IllustrationArchiverConsultation2}
        alt=""
        className="ImageSupport"
      />
      <p>
        Confirmez que vous souhaitez bien supprimer cette consultation (cela
        évite les suppressions par erreur).
      </p>
      <h2>3. Votre consultation n'est plus visible</h2>
      <p>
        Et voilà, votre consultation n'est plus affichée. Si besoin vous pouvez
        toujours la retrouver en affichant les consultations archivées: <br />
        <HashLink to="/support/afficher-consultation-archive">
          Afficher les consultations archivées
        </HashLink>
      </p>

      <q>On espère, que cet article vous a aidé.</q>
      <p> L'équipe Sinobi</p>
    </div>
  );
}
